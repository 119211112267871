<template>
  <el-dialog
    :class="{'rewrite-dialog-style':true, 'remove-footer-area':!hasSlotFooter, 'add-fullscreen-style':dia_fullscreen}"
    :custom-class="!fullscreen?'dialog-private-class':''"
    v-dialogDragChange:true.move="setDialogWidthHeight"
    :fullscreen="dia_fullscreen"
    :visible.sync="dia_visible"
    :top="dia_top"
    :modal="dia_modal"
    :modal-append-to-body="dia_modalAppendToBody"
    :append-to-body="dia_appendToBody"
    :lock-scroll="dia_lockScroll"
    :close-on-click-modal="dia_closeOnClickModal"
    :close-on-press-escape="dia_closeOnPressEscape"
    :show-close="dia_showClose"
    :center="dia_center"
    :destroy-on-close="dia_destroyOnClose"
    :before-close="handleBeforeClose"
    @open="handleOpen"
    @opened="handleOpened"
    @close="handleClose"
    @closed="handleClosed"
  >
    <div
      id="header"
      slot="title"
      class="dialog-header"
    >
      <div class="title-text">
        <slot name="header">{{dia_title}}</slot>
      </div>
      <i
        v-if="dia_showFullscreenIcon"
        class="full-screen-btn el-icon-full-screen"
        @click.stop="handleFullScreen"
      ></i>
    </div>
    <slot></slot>
    <slot
      name="footer"
      slot="footer"
      class="dialog-footer"
    ></slot>
  </el-dialog>
</template>

<script>
  let _self = null;
  export default {
    name: 'ExDialog',
    components: {},
    props: {
      title: { type: String, default: '' },
      width: { type: Number, default: 0 },
      height: { type: Number, default: 0 },
      visible: { type: Boolean, default: false },
      fullscreen: { type: Boolean, default: false },
      top: { type: String, default: '15vh' },
      modal: { type: Boolean, default: true },
      modalAppendToBody: { type: Boolean, default: true },
      appendToBody: { type: Boolean, default: false },
      lockScroll: { type: Boolean, default: true },
      customClass: { type: String, default: '' },
      closeOnClickModal: { type: Boolean, default: false },
      closeOnPressEscape: { type: Boolean, default: true },
      showClose: { type: Boolean, default: true },
      center: { type: Boolean, default: false },
      destroyOnClose: { type: Boolean, default: false },
      beforeClose: { type: Function },

      showFullscreenIcon: { type: Boolean, default: false }
    },
    data () {
      _self = this;
      return {
        dia_visible: this.visible,
        dia_title: this.title,
        dia_width: this.width,
        dia_height: this.height,
        dia_fullscreen: this.fullscreen,
        dia_top: this.top,
        dia_modal: this.modal,
        dia_modalAppendToBody: this.modalAppendToBody,
        dia_appendToBody: this.appendToBody,
        dia_lockScroll: this.lockScroll,
        dia_customClass: this.customClass,
        dia_closeOnClickModal: this.closeOnClickModal,
        dia_closeOnPressEscape: this.closeOnPressEscape,
        dia_showClose: this.showClose,
        dia_center: this.center,
        dia_destroyOnClose: this.destroyOnClose,
        dia_showFullscreenIcon: this.showFullscreenIcon,

        hasSlotFooter: !!this.$slots.footer,
        styleData: {},
        styleHeight: this.height
      }
    },
    watch: {
      visible () {
        this.dia_visible = this.visible;
      },
      fullscreen () {
        this.dia_fullscreen = this.fullscreen;
      },
      title () {
        this.dia_title = this.title;
      },
      height () {
        this.styleHeight = this.height;
      },
      showFullscreenIcon () {
        this.dia_showFullscreenIcon = this.showFullscreenIcon;
      },
      styleHeight (value) {
        this.styleHeight = value;
      }
    },
    methods: {
      hide () {
        this.$emit('update:visible', false);
      },
      handleBeforeClose (done) {
        if (typeof this.beforeClose === 'function') {
          this.beforeClose(done);
        }
        // else {
        this.hide();
        // }
      },
      handleOpen () {
        this.$emit('open');
      },
      handleOpened () {
        this.$emit('opened');
      },
      handleClose () {
        this.$emit('close');
      },
      handleClosed () {
        this.$emit('closed');
      },
      setDialogWidthHeight (setPersonalHeight, style) {
        // console.log(style)
        if (typeof style.width === 'number' && typeof style.height === 'number') {
          style.width = style.width + 'px'
          style.height = style.height + 'px'
        }
        if (setPersonalHeight) {
          if (!this.dia_fullscreen) {
            this.styleHeight = style.height.replace('px', '') * 1;
          }
        }
        this.$emit('receiveDialogWidthHeight', style)
      },
      handleFullScreen () {
        this.dia_fullscreen = !this.dia_fullscreen;
        if (!this.dia_fullscreen) {
          if (JSON.stringify(this.styleData) !== '{}') {
            this.$nextTick(() => {
              let dialogEle = document.querySelector('.el-dialog.dialog-private-class');
              dialogEle.style.marginTop = '0';
              if (dialogEle.style.marginTop == '0px' && Number(dialogEle.style.top.replace('px', '')) < 0) {
                dialogEle.style.top = '0';
              }
              dialogEle.style.width = this.styleData.width;
              dialogEle.style.height = this.styleData.height;
            });
          }
          if (!!this.styleHeight === true) {
            setTimeout(() => {
              let dialogEle = document.getElementsByClassName('dialog-private-class');
              let cur_styleHeight = 0;
              cur_styleHeight = dialogEle[dialogEle.length - 1].clientHeight;
              if (cur_styleHeight === 0) {
                for (let i = 0; i < dialogEle.length; i++) {
                  if (dialogEle[i].clientHeight !== 0) {
                    cur_styleHeight = dialogEle[i].clientHeight;
                    break;
                  }
                }
              }
              this.styleHeight = cur_styleHeight;
              this.$emit('receiveDialogWidthHeight', { height: this.styleHeight + 'px' })
            }, 0);
          }
        } else {
          if (!!this.styleHeight === true) {
            setTimeout(() => {
              this.styleHeight = document.documentElement.clientHeight;
              this.$emit('receiveDialogWidthHeight', { height: this.styleHeight + 'px' })
            }, 0);
          }
        }
      }
    },
    directives: {
      dialogDragChange: {
        bind (el, binding, vnode) {
          //弹框可拉伸最小宽高
          let minWidth = 700;
          let minHeight = 450;

          //初始非全屏
          let isFullScreen = false;

          //当前宽高
          // let nowWidth = 0;
          // let nowHight = 0;

          //当前顶部高度
          // let nowMarginTop = 0;

          //获取弹框头部（这部分可双击全屏）
          const dialogHeaderEl = el.querySelector('.el-dialog__header');

          //弹窗
          const dragDom = el.querySelector('.el-dialog');
          dragDom.style.width = _self.dia_width + 'px';
          dragDom.style.height = _self.dia_height + 'px';

          dragDom.style.overflow = 'hidden';
          let se_resize_ele = document.createElement('i');
          se_resize_ele.classList.add('move-btn');
          se_resize_ele.setAttribute('style', 'position:absolute;right:-3px;bottom:-3px;width:12px;height:12px;z-index:1000000000;transform:rotateZ(45deg);cursor:se-resize;')
          dragDom.appendChild(se_resize_ele);

          // //清除选择头部文字效果
          // dialogHeaderEl.onselectstart = new Function("return false");

          //头部加上可拖动cursor
          dialogHeaderEl.style.cursor = 'move';

          // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
          const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

          let moveDown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft;
            const disY = e.clientY - dialogHeaderEl.offsetTop;

            // 获取到的值带px 正则匹配替换
            let styL, styT;

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
              styL = +document.body.clientWidth * (+sty.left.replace(/%/g, '') / 100);
              styT = +document.body.clientHeight * (+sty.top.replace(/%/g, '') / 100);
            } else {
              styL = +sty.left.replace(/\px/g, '');
              styT = +sty.top.replace(/\px/g, '');
            }

            document.onmousemove = function (e) {
              if (!isFullScreen) {
                // 通过事件委托，计算移动的距离
                const l = e.clientX - disX;
                const t = e.clientY - disY;
                dragDom.style.left = `${l + styL}px`;
                dragDom.style.top = `${t + styT}px`;
                //将此时的位置传出去
                //binding.value({x:e.pageX,y:e.pageY})
              }
            };

            document.onmouseup = function () {
              document.onmousemove = null;
              document.onmouseup = null;
            };
          }
          dialogHeaderEl.onmousedown = moveDown;

          dragDom.onmousemove = function (e) {
            isFullScreen = vnode.context.dia_fullscreen;
            // let moveE = e;
            if (!isFullScreen) {
              if (e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10) {
                dragDom.style.cursor = 'w-resize';
              } else if (el.scrollTop + e.clientY > dragDom.offsetTop + dragDom.clientHeight - 10) {
                dragDom.style.cursor = 's-resize';
              } else {
                dragDom.style.cursor = '';
                dragDom.onmousedown = null;
              }

              dialogHeaderEl.style.cursor = 'move';
              se_resize_ele.style.cursor = 'se-resize';
            } else {
              dragDom.style.cursor = '';
              dialogHeaderEl.style.cursor = 'default';
              se_resize_ele.style.cursor = 'default';
            }

            dragDom.onmousedown = (e) => {
              //存储弹窗实时宽高
              let dialogWidth = 0;
              let dialogHeight = 0;

              const clientX = e.clientX;
              const clientY = e.clientY;
              let elW = dragDom.clientWidth;
              let elH = dragDom.clientHeight;
              let EloffsetLeft = dragDom.offsetLeft;
              let EloffsetTop = dragDom.offsetTop;
              // dragDom.style.userSelect = 'none';

              dialogWidth = elW;
              dialogHeight = elH;

              let ELscrollTop = el.scrollTop;

              //判断点击的位置是不是为头部
              if (clientX > EloffsetLeft && clientX < EloffsetLeft + elW && clientY > EloffsetTop && clientY < EloffsetTop + 50) {
                //如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown;
              } else {
                // 判断指令中是否绑定了函数
                if (binding.expression) {
                  // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                  binding.value(false, { width: dialogWidth + 'px', height: dialogHeight + 'px' });
                }

                document.onmousemove = function (e) {
                  //右侧鼠标拖拽位置
                  if (clientX > EloffsetLeft + elW - 10 && clientX < EloffsetLeft + elW && !isFullScreen) {
                    e.preventDefault();
                    //往左拖拽
                    if (clientX > e.clientX) {
                      if (dragDom.clientWidth < minWidth) {
                        //
                      } else {
                        dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';
                      }

                    }

                    //往右拖拽
                    if (clientX < e.clientX) {
                      dragDom.style.width = elW + (e.clientX - clientX) * 2 + 'px';
                    }
                  }

                  //底部鼠标拖拽位置
                  if (ELscrollTop + clientY > EloffsetTop + elH - 10 && ELscrollTop + clientY < EloffsetTop + elH && !isFullScreen) {
                    e.preventDefault();
                    //往上拖拽
                    if (clientY > e.clientY) {
                      if (dragDom.clientHeight < minHeight) {
                        //
                      } else {
                        dragDom.style.height = elH - (clientY - e.clientY) + 'px';
                      }
                    }

                    //往下拖拽
                    if (clientY < e.clientY) {
                      dragDom.style.height = elH + (e.clientY - clientY) + 'px';
                    }
                  }

                  dialogWidth = dragDom.style.width;
                  dialogHeight = dragDom.style.height;
                  if (JSON.stringify(binding.modifiers) !== '{}' && !!binding.modifiers.move === true) {
                    if (binding.expression) {
                      // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                      binding.value(true, { width: dialogWidth, height: dialogHeight });
                    }
                  }
                };

                //拉伸结束
                document.onmouseup = function () {
                  // 判断指令中是否绑定了函数
                  if (binding.expression) {
                    // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                    binding.value(false, { width: dialogWidth, height: dialogHeight });
                  }
                  document.onmousemove = null;
                  document.onmouseup = null;
                };
              }
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
.rewrite-dialog-style ::v-deep .el-dialog {
  padding: 50px 0 62px;
}
.remove-footer-area ::v-deep .el-dialog {
  padding-bottom: 20px !important;
}
.add-fullscreen-style ::v-deep .is-fullscreen {
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  width: auto !important;
  height: 100% !important;
}
.rewrite-dialog-style ::v-deep .el-dialog__body {
  height: 100%;
  padding-bottom: 0;
  box-sizing: border-box;
}
.rewrite-dialog-style ::v-deep .el-dialog__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  padding: 16px 50px 13px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}
::v-deep .el-dialog__header #header {
  position: relative;
  padding-right: 32px;
}
::v-deep .el-dialog__header #header .title-text {
  overflow: hidden;
  -webkit-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 21px;
  line-height: 21px;
}
::v-deep .el-dialog__header #header .full-screen-btn {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 3px;
  color: #a0a0a0;
  cursor: pointer;
}
::v-deep .el-dialog__header #header .full-screen-btn:hover {
  color: #409eff;
}
::v-deep .el-dialog__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
