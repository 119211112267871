/*
 * @Author: ljf
 * @Date: 2022-05-26 16:17:05
 * @LastEditors: ljf
 * @LastEditTime: 2022-05-27 09:27:08
 * @FilePath: \lxm-admin\src\router\index.js
 * @Description: 
 * 
 */
import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'
import routes from './routers'
import Cookies from 'js-cookie'
import AxiosHelper from "../utils/axiosHelper";

Vue.use(Router);


let router = new Router({
    routes
});
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
router.beforeEach(async (to, from, next) => {

    //判断是否已经登录     
  if (!Cookies.get('userToken') && to.query.token) {
        await AxiosHelper({
            method: 'POST',
            url: '/system/cookie/userToken/' + to.query.token,
            headers: {
                appHost: window.location.host,
            },
        }).catch(error => {
            console.log('token请求失败', error.message);
        });
        next();
  }
    next();
}); 
Vue.mixin({
  beforeRouteLeave: function (to, from, next) {
      if (to.name === 'redirect') {

          if (this.$vnode && this.$vnode.data.keepAlive) {
              if (
                  this.$vnode.parent &&
                  this.$vnode.parent.componentInstance &&
                  this.$vnode.parent.componentInstance.cache
              ) {
                  if (this.$vnode.componentOptions) {
                      var key =
                          this.$vnode.key == null
                              ? this.$vnode.componentOptions.Ctor.cid +
                              (this.$vnode.componentOptions.tag
                                  ? `::${this.$vnode.componentOptions.tag}`
                                  : '')
                              : this.$vnode.key
                      var cache = this.$vnode.parent.componentInstance.cache
                      var keys = this.$vnode.parent.componentInstance.keys
                      if (cache[key]) {
                          if (keys.length) {
                              var index = keys.indexOf(key)
                              if (index > -1) {
                                  keys.splice(index, 1)
                              }
                          }
                          delete cache[key]
                      }
                  }
              }
          }
          this.$destroy()
      }
      next()
  }
})


// 将路由实例对象托管到vuex
// store.commit('global/setRouter', router)
export default router
