/*
 * @Author: ljf
 * @Date: 2022-02-22 18:22:56
 * @LastEditors: ljf
 * @LastEditTime: 2022-10-08 15:13:52
 * @FilePath: \lxm-admin\src\main.js
 * @Description: 
 * 
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";

import store from "./store/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import Util from "./utils/utils";
import "./utils/directives";
import "./assets/css/common.css";
import AxiosHelper from "./utils/axiosHelper";
import { showLoading, hideLoading } from "./utils/loading";
// import 'default-passive-events'
import YfComponents from "yf-components";


var url = import.meta.env.VITE_URL
Vue.prototype.showLoading = showLoading;
Vue.prototype.hideLoading = hideLoading;
Vue.prototype.axios = axios;
Vue.prototype.axiosHelper = AxiosHelper;
Vue.prototype.host = window.location.protocol+"//"+window.location.host;
//文件访问根路径
Vue.prototype.FILE_VIEW_ROOT_RUL = ((Vue.prototype.host.indexOf('localhost')>-1||Vue.prototype.host.indexOf('127.0.0.1'))>-1?'http://localhost:8901':Vue.prototype.host)+"/file/";
//生产系统的菜单类型
Vue.prototype.MENU_TYPE = "pms";
Vue.config.productionTip = false;
Vue.config.devtools = true;
import dialog from "components/common/dialog/index";
Vue.use(dialog);
AxiosHelper.get(
    url
).then((res) => {
    if (res.status === 200) {
        for (let [key, value] of Object.entries(res.data)) {
            Vue.prototype[key] = value
        }
    }

}).catch(() => {
    if (process.env.NODE_ENV === 'development') {
        var config = import.meta('../public/config/config_dev.json')
        for (let [key, value] of Object.entries(config)) {
            Vue.prototype[key] = value
        }
    }
    else {
        console.error('未找到系统配置文件' + url)
    }

}).finally(() => {
    Vue.use(Util);
    Vue.use(YfComponents, {
      MENU_TYPE: "mms",
      router: router,
    });
    Vue.use(ElementUI, {
        size: "small",
    }); 
    new Vue({
        router,
        store,
        render: (h) => h(App),
        created() { },
    }).$mount("#app");
})
