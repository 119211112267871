<!--
 * @Author: ljf
 * @Date: 2022-02-22 18:22:56
 * @LastEditors: ljf
 * @LastEditTime: 2022-04-07 15:27:38
 * @FilePath: \mms-admin\src\App.vue
 * @Description: 
 * 
-->
<template>
<div id="app">
    <router-view />
    
</div>
</template>

<script>
 
export default {
  name: 'app',
  components: {
     
  }
}
</script>

<style lang="scss">
@import "./assets/css/main.css";
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
}
</style>
