
<el-dialog
  :class="{'rewrite-dialog-style':true, 'remove-footer-area':!hasSlotFooter, 'add-fullscreen-style':dia_fullscreen}"
  :custom-class="!fullscreen?'dialog-private-class':''"
  v-dialogDragChange:true.move="setDialogWidthHeight"
  :fullscreen="dia_fullscreen"
  :visible.sync="dia_visible"
  :top="dia_top"
  :modal="dia_modal"
  :modal-append-to-body="dia_modalAppendToBody"
  :append-to-body="dia_appendToBody"
  :lock-scroll="dia_lockScroll"
  :close-on-click-modal="dia_closeOnClickModal"
  :close-on-press-escape="dia_closeOnPressEscape"
  :show-close="dia_showClose"
  :center="dia_center"
  :destroy-on-close="dia_destroyOnClose"
  :before-close="handleBeforeClose"
  @open="handleOpen"
  @opened="handleOpened"
  @close="handleClose"
  @closed="handleClosed"
>
  <div
    id="header"
    slot="title"
    class="dialog-header"
  >
    <div class="title-text">
      <slot name="header">{{dia_title}}</slot>
    </div>
    <i
      v-if="dia_showFullscreenIcon"
      class="full-screen-btn el-icon-full-screen"
      @click.stop="handleFullScreen"
    ></i>
  </div>
  <slot></slot>
  <slot
    name="footer"
    slot="footer"
    class="dialog-footer"
  ></slot>
</el-dialog>
