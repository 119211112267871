/*
 * @Author: ljf
 * @Date: 2022-05-26 16:17:04
 * @LastEditors: ljf
 * @LastEditTime: 2022-05-27 09:27:55
 * @FilePath: \lxm-admin\src\utils\loading.js
 * @Description: 
 * 
 */
import { Loading } from 'element-ui';

let loading;

const startLoading = (word) => {
    word = word ? word : '拼命加载中！';
    loading = Loading.service({
        lock: true,
        text: word, //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        customClass: 'loading-progress', //Loading 的自定义类名
        background: 'rgba(0, 0, 0, 0.6)' //遮罩层背景色
    })
}

const endLoading = () => {
    loading.close();
}

export const showLoading = (word) => {
    startLoading(word);
}

export const hideLoading = () => {
    endLoading();
}